import React, {useState, useEffect} from 'react'
import moment from 'moment'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
import Tabla from '../componentes/Tabla'
import { TraeNombre } from '../hooks/Funciones'
import { NumericFormat } from 'react-number-format'
import { TextField, Button } from '@mui/material';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';


const VerGastos = ({ data, seleccionaRegistro, baseUrl }) => {

	const [filteredRecords, setFilteredRecords] = useState([]);
  	const [selectedMonth, setSelectedMonth] = useState('');

	  useEffect(() => {
		setFilteredRecords(data)
	  },[data])


	const processData = (filteredRecords) => {
		return filteredRecords.map(item => ({
		...item,
		imagenStatus: item.imagen !== '' ? 'lleno' : 'vacio'  // Añades una nueva propiedad
		}));
	};

	const handleMonthChange = (event) => {
		console.log('acá')
		const selected = event.target.value;
		setSelectedMonth(selected);
	
		if (!selected) {
		  // Si no hay mes seleccionado, muestra todos los registros
		  setFilteredRecords(data);
		} else {
		  const filtered = data.filter((record) => {
			const recordDate = new Date(record.fecha); // Usamos 'fecha' de cada registro
			const yearMonth = `${recordDate.getFullYear()}-${(recordDate.getMonth() + 1)
			  .toString()
			  .padStart(2, '0')}`;
			return yearMonth === selected;
		  });
	
		  setFilteredRecords(filtered);
		}
	  };
	
	  // Descargar las imágenes filtradas

	  const generateRandomNumber = () => {
		return Math.floor(10000 + Math.random() * 90000).toString(); // Genera un número aleatorio de 5 dígitos
	  };


	  const downloadFiles = async () => {
		const zip = new JSZip();
	  
		for (const record of filteredRecords) {
		  // Verifica que el registro tenga una imagen
		  if (record.imagen) {
			try {
			  const response = await fetch(`${baseUrl}${record.imagen}`);
			  if (!response.ok) {
				throw new Error('No se pudo descargar la imagen');
			  }
	  
			  const blob = await response.blob();
			  const imageName = record.nombre.split('/').pop(); // Extraer el nombre de la imagen de la URL

			  const randomNumber = generateRandomNumber(); // Genera un número aleatorio de 5 dígitos
        	  const newFileName = `${imageName.split('.')[0]}_${randomNumber}.jpg`; // Añadir el número aleatorio al nombre

			  zip.file(newFileName, blob); // Añadir el blob al zip
	  
			} catch (error) {
			  console.error('Error al descargar la imagen:', error);
			}
		  } else {
			console.warn(`El registro no tiene imagen:`, record);
		  }
		}
	  
		// Genera el zip solo si hay archivos para empaquetar
		zip.generateAsync({ type: 'blob' }).then((content) => {
		  saveAs(content, `facturas_${selectedMonth || 'todos'}.zip`);
		});
	  };
	  


	const columns = [
		{
			title: "Id", 
			field: "id", width: "5%", cellStyle: {padding: 5}, headerStyle: {padding: 5},
		},
		{
			title: 'Img',
			render: (item) => item.imagen !== '' ? <div className="ImgProducto"><img src={`${baseUrl}${item.imagen}`} /></div>
			:<div className="ImgProductoSin"></div>,
			field: "imagenStatus", width: "2%", cellStyle: {padding: 4}, headerStyle: {padding: 0},

		},
		{
			title: "Fecha", 
			field: "fechaformat", 
			cellStyle: {padding: 0}, 
		},
		{
			title: "Nombre", 
			field: "nombre", width: "25%", cellStyle: {padding: 0}, headerStyle: {padding: 5},
		},
		{
			title: "Valor", 
			render: (item) => <NumericFormat displayType="text" value={parseInt(item.valor)} prefix={'$'} thousandSeparator={true} />,
			field: "", 
		},
		{
			title: "Medio",
			field: "mediodepago",
			cellStyle: {padding: 0}, headerStyle: {padding: 5},
		},
		{
			title: "Categoría",
			render: (item) =><span className='estados' style={{backgroundColor: item.colorcategoria, fontSize: 14}}>{item.categoria}</span>,
			field: "categoria", width: "20%", cellStyle: {padding: 0}, headerStyle: {padding: 5},
		},
		{
			render: (item) =><BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'} descarga={true} urlIma={item.imagen} baseUrl={baseUrl}/>,
			width: "48%", cellStyle: {padding: 0}, headerStyle: {padding: 10},
		},

	];
	const actions = [
	]

	return (
		<>
			<Tabla 
				columns={columns} 
				data={processData(filteredRecords)} 
				actions={actions} 
				title='Listado de Gastos' 
				selectedMonth={selectedMonth}
				handleMonthChange={handleMonthChange}
				downloadFiles={downloadFiles}
			/>

		</>
		
	)
}

export default VerGastos