import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faTrashAlt, faDownload} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used

const BotonesFormulario = ({item, SeleccionarCategoria, Roll, acceso, descarga, urlIma, baseUrl}) => {

    // Función para descargar la imagen
    const downloadImage = async (url, filename) => {
        try {
          // Solicitar la imagen desde el servidor
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('No se pudo descargar la imagen');
          }
    
          const blob = await response.blob(); // Convertir la respuesta en un blob (contenido binario)
          const urlBlob = window.URL.createObjectURL(blob); // Crear una URL temporal para descargar el blob
          
          // Crear un enlace temporal para iniciar la descarga
          const link = document.createElement('a');
          link.href = urlBlob;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click(); // Iniciar la descarga
    
          // Limpiar el enlace y liberar la memoria
          link.remove();
          window.URL.revokeObjectURL(urlBlob);
        } catch (error) {
          console.error('Error al descargar la imagen:', error);
        }
      };

	return(
        <div className="col text-center">	
            <button 
                style={{margin: 4}}
                onClick={SeleccionarCategoria(item, 'editar')}
                className="btn boton_estandar_blanco"							
                ><FontAwesomeIcon icon={faEdit} size="lg"/>
            </button>

            {Roll == 'superadmin' || Roll == 'Admin' || acceso == 'full' ? 
                <button 
                style={{margin: 4}}
                    onClick={SeleccionarCategoria(item, 'eliminar')}
                    className="btn boton_estandar_blanco"							
                    ><FontAwesomeIcon icon={faTrashAlt} size="lg"/>
                </button>
            :null}

            {descarga ?
              <>
                {urlIma !== '' ?
                  <button 
                      style={{margin: 4}}
                      onClick={() => downloadImage(`${baseUrl}${urlIma}`, `${item.nombre}.jpg`)}
                      className="btn boton_estandar_blanco"							
                      ><FontAwesomeIcon icon={faDownload} size="lg"/>
                  </button>
                :
                <button 
                    style={{margin: 4}}
                    onClick={() => {}}
                    className="btn boton_estandar_blanco"							
                    ><FontAwesomeIcon icon={faDownload} size="lg" style={{color: '#aaa'}}/>
                </button>
                }

              </>
            :null}

            
        </div>
	)
}



export default BotonesFormulario